/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';
import ResponsiveAutoHeight from 'responsive-auto-height';
import Cookies from 'js-cookie';

var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	var hero = document.querySelector('.hero');
	if (hero) {
		document.documentElement.style.setProperty('--hh', `${hero.offsetHeight}px`);
	}
}

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

calculateSizes();

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Equellize height
//if (document.querySelectorAll('.match-height').length) {
//	new ResponsiveAutoHeight('.match-height');
//}

// Headeroom
import Headroom from 'headroom.js';
var headroom = null;
var headerNotification = document.querySelector('.header-notification');
if (headerNotification) {
	headroom = new Headroom(document.body, { offset: headerNotification.offsetHeight });
	headroom.init();
}
else {
	headroom = new Headroom(document.body);
	headroom.init();
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: false,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1255x'
	});
}

loadLightGallery();

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav-toggler'),
	navigation = document.querySelector('.nav.is-side-nav'),
	navigationContainer = document.querySelector('.nav.is-side-nav .nav-wrapper'),
	navBackground = document.querySelector('.nav-background');
if (navigationToggler && navigation && navigationContainer) {
	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
		} else {
			focusLock.off(navigationContainer);
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	navBackground.addEventListener('click', e => {
		toggle(false);
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Swiper
var sliders = document.querySelectorAll('.image-slideshow');
if (sliders.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		sliders.forEach(el => {
			new Swiper(el, {
				slidesPerView: 'auto',
				loop: true,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				}
			});
		});
	})();
}

//Highlighte swiper
var highlighedSlider = document.querySelectorAll('.highlighted-slider');
if (highlighedSlider.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		highlighedSlider.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = el.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			el.appendChild(swiperButtonPrev);
			el.appendChild(swiperButtonNext);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			var slidesPerView = 3,
				spaceBetween = 48,
				breakpoints = { 768: { slidesPerView: 1 }, 1024: { slidesPerView: 2 } };

			//if (el.classList.contains("speaker-slider")) {
			//	spaceBetween = 48;
			//}

			//if (el.classList.contains("category-slider")) {
			//	slidesPerView = 4;
			//	breakpoints = { 768: { slidesPerView: 1 }, 1024: { slidesPerView: 2 }, 1280: { slidesPerView: 3 } };
			//}

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: slidesPerView,
				breakpoints: breakpoints,
				spaceBetween: spaceBetween,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

// Jump to submitted form
document.querySelectorAll('.contact-block.is-form-block').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (form) {
		if (!anchor) {
			anchor = el.querySelector('.form').getAttribute('id');
		}
		else {
			anchor = anchor.getAttribute('id');
		}

		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});

// Modal
var modalContainer = document.querySelector('.modal-container');
if (modalContainer) {
	const cookieIds = JSON.parse(Cookies.get('modal') || '[]'),
		shownIds = cookieIds.filter(id => cookieIds.indexOf(id) >= 0),
		uid = modalContainer.id;

	if (!shownIds.includes(uid)) {

		var modal = modalContainer.querySelector('.modal');
		modalContainer.classList.add('fade-in');
		modal.classList.add('fade-in');


		var closeModal = function () {
			modalContainer.classList.remove('fade-in');
			modal.classList.remove('fade-in');
			shownIds.push(uid);
			Cookies.set('modal', JSON.stringify(shownIds));
		};

		var modalClose = modalContainer.querySelector('.modal__close');

		modalClose.addEventListener('click', function () {
			closeModal();
		});

		document.addEventListener('keydown', event => {
			if (event.keyCode === 27) {
				closeModal();
			}
		});

		modalContainer.addEventListener('click', event => {
			if (event.target === modalContainer) {
				closeModal();
			}
		});
	}
}